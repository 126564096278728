.mobile {
    display: none;
    max-height: 58px;
}
.tab-bar  .tabbar-svg {
    stroke: #9DB2CE;
}

.mob-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FAFAFA;
    padding: 0 9px;
}

.mobile-tab-bar button,
.mob-top button {
    background-color: transparent;
}

.mob-top button span{
    font-size: 17px;
    font-weight: 400;
    line-height: 22px; 
    letter-spacing: -0.408px;
    color: #007AFF;
}

.mob-top h3{
    font-size: 17px;
    font-weight: 600;
    line-height: 22px; 
    letter-spacing: -0.408px;
    color: black;
}

.mob-navbar {
    position: fixed;
    top: 16px;
    left: 16px;
    right: 16px;
    display: flex;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 14px 12px;
    background: linear-gradient(168deg, #0E3B60 0%, #0E4973 100%);
}

.mob-navbar .logo img{
  width: 110%;
  height: 120%;
  object-fit: contain;
}

.mob-navbar .logo{
    width: 140px;
    height: 28px;
}
.mob-navbar button {
    background-color: transparent;
}

.mobile-tab-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 0 22px 22px;
    padding: 0 15px;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
}

.tab-bar ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0;
    padding: 10px 0;
    list-style: none;
}

.tab-bar ul li {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-bar ul .home-tab-bar {
    width: 55px;
    height: 55px;
    padding: 16px;
    border-radius: 50%;
    background-color: var(--primaryColor) !important;
    box-shadow: 0px 4px 12px 0px rgba(13, 53, 88, 0.50);
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab-bar ul .home-tab {
    margin-top: -50px;
    padding: 10px;
    width: 72px;
    height: 72px;
    border-radius: 50% ;
    background-color: #ffffff;
}


/* Modal */

.fixed-bottom-modal {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  padding: 20px;
  z-index: 1000;
  transition: bottom 0.5s;
}


.fixed-bottom-modal h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: var(--primaryColor);
}

.fixed-bottom-modal .close-button{
    background: none;   
    width: 27px;
    height: 27px;
}

.fixed-bottom-modal .close-button img{
    width: 27px;
    height: 27px;
}

.modal-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.option-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.option-list label {
    display: flex;
    align-items: center;
    background-color: #ECF5FD;
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid #9DB2CE;
    cursor: pointer;
    font-size: 14px;
  }

.option-list label input[type="radio"] {
    margin-right: 8px;
}


  .personal-logout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    background-color: var(--secondColor);
    padding: 6px;
    border-radius: 4px;
  }

  .logout{
    background-color: var(--primaryColor);
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .personal-info {
    display: flex;
    align-items: center;
    gap: 10px;

  }
  .personal-info span{
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
    color: var(--primaryColor);
  }
  .group-name {
    font-weight: 500;
    font-size: 16px;
    color: #0D3558;
  }
  
  .close-button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  .close-icon {
    width: 20px;
    height: 20px;
  }
  
  
  .language-code {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #0D3558;
  }
  
 
  .currency-symbol {
    font-weight: 400;
    font-size: 12px;
    color: #0D3558;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-top: -20px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    background-color: var(--secondColor);
  }
  .currency-rate {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
  }
  .currency-rate span {
    margin-top: 4px;
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    color: #0D3558;
  }
  

  