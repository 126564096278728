.dropdown {
    position: relative;
    display: inline-block;
    min-width: 195px;
}

.dropdown-head {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background-color: var(--primaryColor);
    transition: 0.4s;
    cursor: pointer;
}

.dropdown-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown-content {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    background-color: var(--white);
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 12px 25px rgba(127, 156, 180, 0.2);
    opacity: 0;
    z-index: 1;
    transform: translateY(-10px);
    visibility: hidden;
    transition: all 0.3s ease;
}

.dropdown-content.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdown-content li {
    padding: 12px 16px;
    transition: 0.3s;
    border-bottom: 1px solid var(--input-color, #F5F5F5);
}

.dropdown-content li span {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: var(--primaryColor);
    transition: 0.3s;
}

.dropdown-content li:hover {
    background-color: var(--primaryColor);
}

.dropdown-content li:hover span {
    color: var(--grayWhite4);
}

.dropdown-left span {
    padding-left: 8px;
    border-left: 1px solid var(--grayWhite1);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
    transition: 0.4s;
    color: var(--grayWhite1);
}

.dropdown-head_hover {
    background-color: var(--grayWhite1) !important;
}

.dropdown-head_hover span {
    color: var(--primaryColor) !important;
    border-left: 1px solid var(--primaryColor) !important;
}

.dropdown-svg {
    display: flex;
    align-items: center;
}

.dropdown-svg svg {
    stroke: #FAFAFA;
}

.dropdown-head_hover .dropdown-svg svg {
    stroke: var(--primaryColor) !important;
}

.dropdown ul li:first-child {
    border-radius: 12px 12px 0 0;
}

.dropdown ul li:last-child {
    border-radius: 0 0 12px 12px;
}

/* SVG info */
.info-btn {
    background-color: transparent;
}

.info-btn svg {
    fill: #FAFAFA;
    stroke: #FAFAFA;
    transition: 0.4s;
}

.info-btn svg {
    transition: 0.4s;
    stroke: #0D3558;
}

.dropdown-head_hover .info-btn svg {
    fill: #0D3558 !important;
    stroke: #0D3558 !important;
}

.dropdown-head_hover .info-btn svg {
    stroke: #FAFAFA !important;
}