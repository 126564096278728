.skeletonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 70vh;
}

.skeletonLeft {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  align-items: center;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

.skeletonText {
  width: 510px !important;
  height: 17px;
  margin-top: 17px;
}

.skeletonBars {
  display: flex;
  width: 60%;
  align-items: flex-end;
  flex-direction: row;
  height: 100%;
  gap: 10px;
  overflow-x: hidden;
}

.skeletonBar {
  width: 29px !important;
}

.skeleton {
  height: 220px;
}

@media (max-width: 576px) {
  .skeletonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 70vh;
    flex-direction: column !important;
    gap: 18px;
  }
  .skeletonBars {
    width: 90% !important;
  }
  .skeletonLeft {
    width: 100%;
  }
  .skeletonText {
    display: none !important;
  }
  .circle_chart_skeleton {
    width: 80% !important;
    justify-content: center !important;
  }
}
