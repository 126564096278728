.header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    flex-wrap: wrap;
    gap: 12px 0;
    position: relative;
}

.left-container {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
}

.tablet-dropdown {
    background-color: white;
    position: absolute;
    top: 55px;
    border-radius: 20px;
    padding: 10px;
    width: 100%;
}

.dropdown-t {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}



.header .right-container {
    display: flex;
    align-items: center;
    gap: 20px;
}


.menu-tablet {
    display: none;
}


.right-container button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
}


@media screen and (max-width:1224px) {
    .header-top {
        position: relative;
        z-index: 1;
    }

    .left-container {
        display: none;
        position: absolute;
        top: 65px;
        background-color: #0D3558;
        border-radius: 12px;
        padding: 12px;
        z-index: 10;
    }

    .menu-tablet {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding: 2px;
        border-radius: 4px;
    }

    .menu-tablet:hover .left-container {
        display: block;
    }
}


@media screen and (max-width:1224px) {
    .header-top {
        position: relative;
        z-index: 1;
    }

    .left-container {
        display: none;
        position: absolute;
        top: 65px;
        background-color: #0D3558;
        border-radius: 12px;
        padding: 12px;
        z-index: 10;
    }
}

@media (max-width: 600px) {
    .header {
        display: none;
    }
}