.chart {
  width: 350px !important;
  height: 350px !important;
  transition: width 0.3s ease, height 0.3s ease !important;
}

.donut-entry {
  font-weight: 600;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s ease;
  vertical-align: middle;
}

.donut-line:hover .donut-entry {
  white-space: normal;
  width: max-content;
  max-width: 340px;
  padding: 3px 0;
  border-radius: 4px;
  overflow: visible;
  text-overflow: unset;
}

@media (max-width: 768px) {
  .chart {
    width: 300px !important;
    height: 300px !important;
  }
}

@media (max-width: 480px) {
  .chart {
    width: 250px !important;
    height: 250px !important;
  }
}
