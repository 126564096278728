.nav-container{
    padding: 32px 24px;
    background: var(--gradient, linear-gradient(166deg, #031C30 0%, #0E4971 45.6%));
    width: 325px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transition: all .3s;
    overflow: auto;
}

.nav-container:before {
    background: url(../../../assets/images/pattern-menu.3643130193113da1e325.webp);
    background-size: 100% 100%;
    content: "";
    filter: brightness(.5);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
}

.mobile-background{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 22;
}
.navbar {
    display: flex;
    flex-direction: column;
    gap: 48px;
}
.nav-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-logo .logo{
    width: 130px;
}

.nav-logo .logo img{
    width: 100%;
    height: 100%;
}

.nav-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.nav-list li {
    padding: 8px;
    transition: .2s;
}
.nav-list li a{
    display: flex;
    align-items: center;
    gap: 16px;
    color: #EFF2F4;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.16px;
    transition: .3s;
}

.nav-list li span{
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease
}

.nav-list li:hover{
    background-color: var(--white);
    border-radius: 4px;
}

.nav-list li:hover a{
    color: var(--primaryColor);
}

.nav-list li a svg{
    stroke: white;
    transition: .2s;
}
.nav-list li:hover svg{
    stroke: var(--primaryColor);
}

.nav-list li:has(.active) {
    background-color: var(--white);
    border-radius: 4px;
}
.nav-list .active span{
    color: var(--primaryColor);
}
.nav-list .active .svg-color{
    stroke: var(--primaryColor);
}

.nav-container::-webkit-scrollbar {
    width: 3px;
  }

.nav-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.nav-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.minimal-nav-container{
    width: 90px;
}

.minimal-nav .nav-logo .logo{
    display: none;
}

.minimal-nav .nav-logo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.minimal-nav .nav-logo button {
    transform: rotate(45deg);
}

.minimal-nav  .nav-list span {
    opacity: 0;
    transition: opacity 0.3s ease;
    visibility: hidden;
    overflow: hidden;
    display: none;
}

.minimal-nav .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
