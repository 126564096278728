.background{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color:rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 22;
}


.modal-info {
    background-color: var(--white);
    border-radius: 20px;
    padding: 24px 20px;
    min-width: 600px;
    min-height: 400px;
    box-shadow: 0px 12px 25px 0px rgba(127, 156, 180, 0.20);
    z-index: 2000 ;
    transition: 1s
}

.modal-info-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #a8b0b6;
}

.modal-info-main {
    margin-top: 20px;
}

.modal-info-main ul {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.modal-info-main ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}

.modal-info-main ul li .left {
    display: flex;
    align-items: center;
    gap: 12px;
}

.modal-info-main ul li .left h6 {
    font-size: 16px;
    font-weight: 700;
    color: var(--primaryColor);
}
.modal-info-main ul li .right h6 {
    font-size: 16px;
    font-weight: 400;
}
.modal-info-main ul li .right {
    border-radius: 4px;
    min-width: 269px;
}

.modal-info-main ul li .right  {
    display: flex;
    align-items: center;
    gap: 5px;
}
.modal-info-main ul li .right h6, 
.modal-info-main ul li .right span{
    font-size: 16px;
    font-weight: 600;
    color: var(--primaryColor);
    display: inline-block;
} 

.modal-info-top .close-modal {
    display: flex;
    align-items: center;
    gap: 12px;
}
.modal-info-top h2{
    font-weight: 700;
    line-height: 22px;
    font-size: 24px;
    color: var(--primaryColor);
}
.modal-info-top .close-modal .whatsapp ,
.fixed-bottom-modal .modal-top button,
.modal-info-top .close-modal .close {
    width: 32px;
    height: 32px;
    background: transparent;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fixed-bottom-modal .modal-top button,
.modal-info-top .close-modal .close {
   border: 1px solid #031C30;
}


@media (max-width: 754px) {
    .modal-info {
        min-width: 80%;
        padding: 20px 16px;
        top: 50%;  
    }

    .modal-info-top h2 {
        font-size: 1.2em;
    }
    .modal-info-main ul li .left h6{
        font-size: 14px;
    }
    .modal-info-main ul li .right h6 {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .modal-info {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        min-width: unset;
        width: 100%;
        max-width: 100%;
        min-height: fit-content;
        padding: 20px;
        border-radius: 20px 20px 0 0;
        top: unset;
        transform: none;
    }

    .modal-info-top h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px
    }
    .modal-info-main ul{
        gap: 8px;
    }
    .modal-info-main ul li{
        align-items: flex-start !important;
    }
    .modal-info-main ul li .left h6 {
        font-size: 14px;
    }
    .modal-info-main ul li .right span,
    .modal-info-main ul li .right h6{
        font-size: 14px;
        font-weight: 500;
    }
    .modal-info-main ul li .right {
        min-width: unset;
        width: 100%;
        flex-direction: column;
    }
    .modal-info-main ul li .right{
        width: 160px;
        align-items: flex-start;
    }
    .modal-info-main ul li .left svg {
        display: none;
      }
}