* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--grayWhite2);
}

code {
  font-family: "Montserrat", sans-serif;
}

:root {
  --primaryColor: #0d3558;
  --secondColor: #ecf5fd;
  --white: #fff;
  --grayWhite1: #fafafa;
  --grayWhite2: #f6f6f6;
  --grayWhite3: #eff2f4;
  --grayWhite4: #f5f5f5;
  --black: #000;
  --lineGradientPrimary: linear-gradient(78deg, #031c30 -0.39%, #0e4971 65.6%);
  --gray: #a8a8a8;
  --lightBlue: #249bf4;
}

ul li {
  list-style: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

a,
button,
input {
  outline: none;
  border: none;
}

button {
  cursor: pointer;
  border: none;
}

.svg-color:has svg {
  width: 32px;
  height: 32px;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--white);
  z-index: 999;
}

.no-scroll {
  overflow: hidden;
}

/*  */

main {
  position: absolute;
  right: 0;
  padding: 32px 10px;
}

.page {
  display: flex;
}
