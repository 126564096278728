.settingContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--2xs, 8px);
  min-height: 70px;
}

.settingContainerItem {
  border-radius: var(--s, 8px);
  border: 1px solid var(--Secondary-color, #ecf5fd);
  display: flex;
  padding: var(--2xs, 4px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  width: 31%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingContainerItem img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.settingContainerItem p {
  color: var(--Primary-color, #0d3558);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 183.333% */
}

.settingContainerItem .flagCircle {
  border-radius: 50px;
  background: var(--Secondary-color, #ecf5fd);
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}
