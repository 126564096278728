.no-information {
    height: 45px;
    padding-left: 16px ;
    border-left:3px solid var(--primaryColor);
    display: flex;
    align-items: center;
}

.no-information h2{
    font-size: 16px;
    font-weight: 600;
    color: var(--primaryColor);
}


