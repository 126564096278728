.charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
    gap: 20px;
}

.recharts-tooltip-wrapper {
    pointer-events: none;
}

.chart-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    color: var(--white);
    font-weight: 600;
}

.chart-info-mob {
    text-align: center;
    margin-bottom: 14px;
    color: var(--white);
}

.chart-info-mob h3 {
    text-align: center;
    color: var(--white);
    font-size: 20px;
}

.chart-info-mob p {
    text-align: center;
    color: var(--white);
    font-size: 16px;
}

.chart-card-circle {
    padding: 16px;
    border-radius: 8px;
    color: '#fff';
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 2fr 1fr;
    align-items: last baseline;

}

.chart-card {
    padding: 16px;
    border-radius: 8px;
    color: '#fff';
    width: 100%;
    transition: .5s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chart-card-shadow {
    min-height: 260px;
}

.chart-card-shadow:hover {
    box-shadow: rgba(0, 0, 0, 0.34) 0px 30px 90px;
}

.chart-card-circle {
    outline: none;
}

.recharts-wrapper {
    outline: none;
}

.chart-container:focus {
    outline: none;
}

.chart-card-single {
    background-color: var(--white);
    padding: 24px;
    border-radius: 12px;
    width: 100%;
    height: 50vh;
}

.donut-line {
    display: flex;
    align-items: center;
    /* margin-bottom: 12px; */
    gap: 5px;
    background-color: #F2F2F7;
    padding: 15px 8px;
    border-radius: 4px;
    width: 100%;
}

.donut-scroll {
    padding-right: 5px;
}

.donut-scroll::-webkit-scrollbar {
    width: 3px;
}

.donut-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

.donut-scroll::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #F2F2F7;
    outline: 1px solid #F2F2F7;
}

.recharts-tooltip-wrapper {
    z-index: 100;
}