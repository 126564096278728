.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-head {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background-color: var(--primaryColor);
    transition: 0.4s;
    cursor: pointer;
}

.dropdown-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown-content {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    background-color: var(--white);
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 12px 25px rgba(127, 156, 180, 0.2);
    opacity: 0;
    z-index: 1;
    transform: translateY(-10px);
    visibility: hidden;
    transition: all 0.3s ease;
}

.dropdown-content.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdown-content li {
    padding: 12px 16px;
    transition: 0.3s;
    border-bottom: 1px solid var(--input-color, #F5F5F5);
}

.dropdown-content li span {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: var(--primaryColor);
    transition: 0.3s;
}

.dropdown-content li:hover {
    background-color: var(--primaryColor);
}

.dropdown-content li:hover span {
    color: var(--grayWhite4);
}

.dropdown-left span {
    padding-left: 8px;
    border-left: 1px solid var(--grayWhite1);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.2px;
    transition: 0.4s;
    color: var(--grayWhite1);
}

.dropdown-head_hover {
    background-color: var(--grayWhite1) !important;
}

.dropdown-head_hover span {
    color: var(--primaryColor) !important;
    border-left: 1px solid var(--primaryColor) !important;
}

.dropdown-head_hover .dropdown-svg .svg-color {
    stroke: var(--primaryColor) !important;
}

.dropdown-head_hover .dropdown-left .currency svg {
    stroke: #fff !important;
    fill: #fff !important;
}

.dropdown ul li:first-child {
    border-radius: 12px 12px 0 0;
}

.dropdown ul li:last-child {
    border-radius: 0 0 12px 12px;
}

/* SVG info */

.info-white {
    fill: #FAFAFA;
    stroke: #FAFAFA;
    transition: 0.4s;
}

.info-light {
    transition: 0.4s;
    stroke: #0D3558;
}

.dropdown-head_hover .info-white {
    fill: #0D3558 !important;
    stroke: #0D3558 !important;
}

.dropdown-head_hover .info-light {
    stroke: #FAFAFA !important;
}

.dropdown-head-end {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background-color: var(--white) !important;
}

.dropdown-head-end .logout {
    stroke: #0D3558;
}

.dropdown-head-end span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: var(--primaryColor);
}

.dropdown-content-logout {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: var(--white);
    width: 100%;
    padding: 12px 8px;
    border-radius: 12px;
    box-shadow: 0px 12px 25px 0px rgba(127, 156, 180, 0.2);
    z-index: 1;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: all 0.3s ease;

}

.dropdown-content-logout.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdown:hover .dropdown-content-logout {
    display: block;
}

.flags {
    display: flex;
    align-items: center;
    gap: 24px;
    z-index: 22;
}

.flag-circle {
    width: 36px;
    height: 36px;
    border-radius: 50px;
    background-color: var(--secondColor);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.flag {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.logout-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grayWhite2) !important;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    gap: 12px;
    margin-top: 13px;
}

.logout-btn span {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: var(--primaryColor);
}

.menu-tablet {
    display: none;
}


.right-container button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    cursor: pointer;
}

.info-btn {
    background-color: transparent;
}


@media (max-width: 600px) {
    .flags-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
        margin-bottom: 30px;
        gap: 8px;
    }

    .flags-container .flag {
        border-radius: 8px;
        border: 1px solid var(--secondColor);
        padding: 5px;
    }
}