@media screen and (max-width:1296px) {
    main {
        padding: 30px 12px;
    }

    .nav-container {
        width: 250px;
    }

    .minimal-nav-container {
        width: 90px;
    }
}

@media screen and (max-width:1261px) {
    .charts {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .chart-card {
        min-height: 220px;
        max-height: 300px;
    }

    .diagram-card {
        min-height: 40vh !important;
    }
}




@media screen and (max-width:877px) {
    .charts {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
        gap: 10px;
    }

    .chart-card {
        min-height: 140px;
        max-height: 190px;
        padding: 10px;
    }

    .chart-info {
        margin-bottom: 10px;
        flex-direction: column;
        align-items: center;
    }

}




/* Responsive Styles */

@media (max-width: 600px) {
    main {
        position: relative;
        padding: 16px;
        width: 100%;
        padding-top: 94px;
        padding-bottom: 90px;
    }

    .charts {
        margin-top: -12px;
    }

    .chart-card-circle {
        grid-template-rows: 1fr;
    }

    .diagram-card {
        min-height: 30vh !important;
    }

    .nav-container {
        position: fixed;
        z-index: 222;
        width: 70%;
        transition: left 0.5s ease;
    }

    .mobile {
        display: block;
        background-color: var(--grayWhite2);
        width: 100%;
        position: fixed;
        height: 58px;
        z-index: 2;
    }

    .page {
        display: flex;
        flex-direction: column;
    }

    .mobile-tab-bar {
        display: block;
    }


    .charts {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 10px;
    }

    .responsive-chart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-radius: 8px;
        width: 100%;
    }
}


@media (max-width: 480px) {
    .mobile-tab-bar {
        padding: 0 10px;
    }

    .home-tab {
        width: 45px;
        height: 45px;
        margin-top: -20px;
    }

    .tab-bar ul li {
        padding: 8px;
    }

    .tab-bar ul {
        gap: 5px;
    }

    .chart-card-single {
        height: 35vh;
    }

    .charts {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 10px;
    }

    .chart-card {
        max-height: 145px;
        min-height: 120px;
        padding: 10px;
    }

    .responsive-chart {
        min-height: 140px;
        padding: 5px;
    }

    .donut-line span {
        font-size: 12px;
    }

    .pieChartText h3 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #8E8E93;
    }

    .pieChartText p {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: #1C1C1E;
    }

    .single-diagram {
        stroke-dasharray: 2 2;
    }

}


@media (max-width: 360px) {
    .charts {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
        gap: 10px;
    }

    .chart-card {
        max-height: 125px;
        min-height: 110px;
        padding: 10px;
    }

    .responsive-chart {
        min-height: 100px;
        padding: 5px;
    }
}

@media screen and (max-width: 300px) {
    .chart-container {
        width: 100%;
        min-height: 370px;
    }

    .table-view {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .table-view {
        font-size: 14px;
    }
}

@media screen and (max-width: 300px) {
    .chart-container {
        width: 100%;
        min-height: 370px;
    }

    .table-view {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }

    .table-view {
        font-size: 14px;
    }

    .charts {
        grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
        gap: 10px;
    }

    .chart-card {
        min-height: 110px;
        padding: 10px;
    }
}