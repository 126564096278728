.dropdown {
    position: relative;
    display: inline-block;
    max-width: 120px;
    z-index: 11;
}

.dropdown-head {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background-color: var(--primaryColor);
    transition: 0.4s;
    cursor: pointer;
}

.dropdown-left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown-content {
    position: absolute;
    top: calc(100% + 8px); 
    left: 0;
    background-color: var(--white);
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 12px 25px rgba(127, 156, 180, 0.2);
    opacity: 0;
    z-index: 1;
    transform: translateY(-10px);
    visibility: hidden;
    transition: all  0.3s ease;
}

.dropdown-content.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.dropdown-content li span {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: var(--primaryColor);
    transition: 0.3s;
}

.dropdown-content li:hover {
    background-color: var(--primaryColor);
}

.dropdown-content li:hover span {
    color: var(--grayWhite4);
}

.dropdown-left span {
    padding-left: 8px;
    border-left: 1px solid var(--grayWhite1);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.2px;
    transition: 0.4s;
    color: var(--grayWhite1);
}

.dropdown-head_hover {
    background-color: var(--grayWhite1)  !important;
}

.dropdown-head_hover span{
    color: var(--primaryColor)!important;
    border-left: 1px solid var(--primaryColor)!important;
}

.dropdown-head_hover .dropdown-svg .svg-color{
    stroke: var(--primaryColor)!important;
}

.dropdown-head_hover .dropdown-left .currency svg{
    stroke: #fff !important;
    fill: #fff !important;
}

.dropdown ul li:first-child{
    border-radius: 12px 12px 0 0;
} 

.dropdown ul li:last-child{
    border-radius: 0 0 12px 12px;
} 

/* Currency */
.dropdown-svg svg{
    width: 16px;
    height: 12px;
}

.currency {
    display: flex;
    width: 30px;
    height: 30px;
    padding: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: var(--white);
    box-shadow: 0px 4px 10px 0px rgba(14, 73, 113, 0.10);
}

.currency span {
    font-size: 14px;
    font-weight: 900;
    color: var(--primaryColor);
    line-height: 22px;
}

.currency-circle {
    fill: var(--primaryColor);
    
}


.dropdown-content-currency {
    position: absolute;
    top: calc(100% + 8px); 
    left: 0;
    background-color: var(--white);
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 12px 25px rgba(127, 156, 180, 0.2);
    z-index: 1;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: all  0.3s ease;
}


.dropdown-content-currency.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}


.dropdown-content-currency li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    transition: 0.4s;
    border-bottom: 1px solid var(--nput-color, #F5F5F5);
}

.dropdown-content-currency li span{
    font-size: 16px;
    font-weight: 500;
    line-height: 16px; 
    color: var(--primaryColor);
    transition: 0.4s;
}

.dropdown-content-currency li:hover{
    background-color: var(--primaryColor);
}

.dropdown-content-currency li:hover span{
    color: var(--grayWhite4);
}

.dropdown-content-currency li:hover .currency{
    background-color: var(--white);
}
.dropdown-content-currency li:hover .currency-circle{
    fill: var(--primaryColor);
}


.dropdown:hover .dropdown-content-currency {
    display: block;
}

.dropdown-head_hover {
    background-color: var(--grayWhite1) !important;
}

.dropdown-head_hover span{
    color: var(--primaryColor) !important;
    border-left: 1px solid var(--primaryColor) !important;
}

.dropdown-head_hover .currency{
    background-color: var(--primaryColor) !important;
}

.dropdown-head_hover .currency-circle{
    fill: var(--white) !important;
}


.header .right-container{
    display: flex;
    align-items: center;
    gap: 20px;
}
