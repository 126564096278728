.months {
    padding: 8px 12px;
    background-color: var(--secondColor);
    border-radius: 12px;
    width: 370px;
}

.months-list {
    display: flex;
    align-items: center;
    gap: 20px;
}

.months-list li {
    padding: 8px 12px;
    cursor: pointer;
    position: relative;
    border-radius: 12px;
    border: 1px solid transparent;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.months-list li span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: var(--primaryColor);
    display: inline-block;
    transition: transform 0.3s ease, color 0.3s ease;
}

.months-list .active-list {
    border-radius: 12px;
    background-color: var(--white);
}

.months-list .active-list span {
    font-weight: 500;
    color: var(--primaryDarkColor);
}


.months-list .active-list {
    position: relative;
}




@media screen and (max-width:877px){
    .months{
        width: 100%;
    }
}
