.main-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--white);
  margin-bottom: 20px;
}

.main-top h3 {
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryColor);
}

.main-top button {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 12px;
  border-radius: 12px;
  background-color: var(--primaryColor);
  color: var(--grayWhite1);
  font-size: 16px;
  font-weight: 500;
}

.totalCashBtn {
  width: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 12px;
  border-radius: 12px;
  background-color: var(--primaryColor);
  color: var(--grayWhite1);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 754px) {
  .main-top h3 {
    font-size: 14px;
    font-weight: 700;
  }

  .main-top button {
    padding: 4px;
    border-radius: 4px;
    width: 38px;
  }

  .main-top .button-text {
    display: none;
  }
}
