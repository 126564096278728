.table-container {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-container table,
th,
td {
  border: none;
  border-collapse: collapse;
}

.table-container table {
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  background-color: var(--white);
}

.table-container thead {
  background-color: #ecf5fd;
  text-align: left;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 0;
  /* border: 1px solid #249bf4; */
}

.table-container thead tr th {
  color: var(--Primary-color, #0d3558) !important;
}

.table-container tr:nth-child(1) {
  color: var(--Primary-color, #0d3558) !important;
}

.table-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-container tr td {
  font-weight: 500;
}

.table-container th,
td {
  color: var(--primaryColor);
  padding: 8px 12px;
}

.table-card .table-container tbody tr td,
.table-card .table-container tbody tr {
  /* border: 1px solid #a8a8a8; */
}

.table-card .table-container tbody tr:hover {
  background-color: var(--secondColor) !important;
  cursor: pointer;
}

.table-card {
  padding: 12px;
  overflow-y: "auto";
  background-color: white;
  border-radius: 8px;
}

.table-container::-webkit-scrollbar {
  width: 3px;
}

.table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #d1d1d14b;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  outline: 1px solid #d1d1d1;
  border-radius: 100px;
}
