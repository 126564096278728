.container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/login-bg.webp);
  background-size: cover;
  z-index: -100;
}

.login-container {
  display: flex;
  width: 80%;
  max-width: 1000px;
  min-height: 491px;

}

.left-panel {
  flex: 1;
  background: var(--lineGradientPrimary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 72px 32px 32px;
  border-radius: 40px 0 0 40px;
  text-align: center;
  z-index: -1;
  width: 40%;
  position: relative;
}

.left-panel::before{
  background: url(../../assets//images/pattern-menu.3643130193113da1e325.webp);
  background-size: 100% 100%;
  content: "";
  filter: brightness(.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%; 
}

.left-panel h1 {
  color: var(--grayWhite1);
  font-size: 2.25rem;
  margin-bottom: 10px;
}

.left-panel p {
  font-size: 0.875rem;
  color: var(--white);
}

.right-panel {
  width: 40%;
  border-radius: 2.5rem;
  margin-left: -40px;
  z-index: 2;
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  box-shadow: 0px 12px 25px 0px rgba(127, 156, 180, 0.20);
}

.right-panel h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: var(--primaryColor);
  text-align: center;
}

.input-group {
  margin-bottom: 16px;
  width: 100%;
}

.input-group label {
  display: inline-block;
  font-size: 0.875rem;
  margin-bottom: 4px;
  color: var(--primaryColor);
  font-weight: 500;
  line-height: 20px
}

.input-group .input {
  padding: 10px 18px;
  font-size: 0.75rem;
  border-radius: 15px;
  background-color: var(--grayWhite4);
  border: 1px solid var(--grayWhite4);
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.input-group input{
  background-color: var(--grayWhite4);
  width: 80%;
  height: 28px;
}

.toggle-password {
  background: none;
  border: none;
  color: var(--secondColor);
  cursor: pointer;
}

.login-button {
  height: 3rem;
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  color: white;
  background-color:var(--primaryColor);
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 16px;
  font-weight: 600;
  line-height: 22px;
}


.link {
  display: inline-block;
  margin-top: 24px;
  text-align: center;
  color: var(--primaryColor);
  font-size: 0.9rem;
  text-decoration: none;
}

.forgot-password-link:hover {
  text-decoration: none;
}


@media screen and (max-width:780px){
  .login-container{
    flex-direction: column;
    align-items: center

  }
  .left-panel {
    border-radius: 32px 32px 0 0;
    width: 80%;
    padding: 32px 32px 82px 32px;
    
  }
  .right-panel {
    width: 80%;
    margin-left: 0;
    margin-top: -60px;
  }
  .left-panel h1 {
    margin-bottom: 2px;
  }
}

@media screen and (max-width:571px){
  .login-container{
    flex-direction: column;
    align-items: center;
    width: 90%;

  }
  .left-panel {
    border-radius: 20px 20px 0 0;
    width: 90%;
    padding: 24px 14px 82px 14px;
    
  }
  .right-panel {
    width: 90%;
    margin-left: 0;
    padding: 16px;
    margin-top: -60px;
  }
  .left-panel h1 {
    margin-bottom: 16px;
    font-size: 20px;
  }
  .left-panel p {
    font-size: 12px;
    font-weight: 400;
  }
  
.right-panel h2 {
  font-size: 24px;
  margin-bottom: 12px;
}
.right-panel{
  font-size: 1;
}
}