.two-charts {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    flex-grow: 1;
    height: calc(100vh - 268px);
}

@media screen and (max-width:1261px) {
    .two-charts {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 600px) {
    .two-charts {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100% !important;
    }
}